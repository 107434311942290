import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    Id: 0,
    FelhasznaloNevReg: "",
    JelszoReg: "",
    JelszoUjra: "",
    TeljesNev: "",
    Email: "",
    //kezdoIskolaId: 5,
    //kezdoKepzesId: 1,
    jog: 0,
    aktiv: 1,
    Salt: "",
    Hash: "",
    felhasznaloModositas: false,
    oktatasiAzonosito: "",
    API_URL: "",
    //API_URL:"https://ttf.regoba.com/",
    //API_URL:"https://jelentkezesirendszerbackend20230718.azurewebsites.net/",
    //API_URL: "https://localhost:7259/",
    possible: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
    key: "1d93f8a843855a0bf41d68584b51bba33b1361a760d5c3fdd2f2578b9023f2cc",
    userSalt: "",
    logged: false,
    loginPerRegistry: false,
    Uid: "",
    teljesNev: "",
    jogosultsag: 0,
    intezmenyId: 0,
    felhasznaloNev: "",
    figyeles: false,
    //Intézmény karbantartása
    intezmenyModositas: false,
    Inev: "",
    Kapcstarto_nev: "",
    Kapcstarto_email: "",
    Kapcstarto_tel: "",
    Kapcstarto_nev_2: "",
    Kapcstarto_email_2: "",
    Kapcstarto_tel_2: "",
    //Képzések karbantartása
    /*Knev: "",
    Khelyszin: "",
    Kindulas: "",
    Kjelentkezes: "",
    Ktipus: 0,
    Khossz: "",
    Kfelelos: "",
    KepzoIntId: 0,
    Url: "",
    Elofeltetel: "",*/
    //Kepzes
    kepzesModositas: false,
    kepzes: {
      id: 0,
      knev: "",
      khelyszin: "",
      kindulas: "",
      kjelentkezes: "",
      ktipus: 1,
      khossz: "",
      kfelelos: "",
      kepzoIntId: 5,
      elofeltetel: "",
      url: "",
    },
    //Tabor
    taborModositas: false,
    tabor: {
      id: 0,
      taborNev: "",
      taborKezd: "",
      taborVeg: "",
      helyszinNev: "",
      helyszinIr: "",
      helyszinVaros: "",
      helyszinCim: "",
      kontakt: "",
      kontaktTelefon: "",
      kontaktEmail: "",
      maxletszam: 0,
      url: "",
    },
    //Menű
    menu: [
      {
        header: "Jelentkezési rendszer",
        hidden: false,
        hiddenOnCollapse: true,
      },
      {
        href: "/",
        title: "Kezdőoldal",
        icon: "fa fa-home",
      },
      {
        href: "/login",
        title: "Bejelentkezés",
        icon: "fa fa-user-tie",
      },
      {
        href: "/felhasznalok",
        title: "Felhasználók",
        icon: "fa fa-users",
        hidden: true,
      },
      {
        href: "/intezmenyek",
        title: "Intézmények",
        icon: "fa fa-landmark",
        hidden: true,
      },
      {
        href: "/kepzesek",
        title: "Képzések",
        icon: "fa fa-graduation-cap",
        hidden: true,
      },
      {
        href: "/taborok",
        title: "Táborok",
        icon: "fa fa-campground",
        hidden: true,
      },
      {
        href: "/elojelentkezesek",
        title: "Előjelentkezések",
        icon: "fa fa-chart-area",
        hidden: true,
      },
      {
        href: "/backupRestore",
        title: "Mentés/Visszatöltés",
        icon: "fa fa-download",
        hidden: true,
        /*child: [
            {
              href: '/charts/sublink',
              title: 'Sub Link'
            }
          ]*/
      },
    ],
  },
  getters: {},
  mutations: {
    logout(state) {
      let url = state.API_URL + "Logout/" + state.Uid;
      axios
        .post(url)
        .then((response) => {
          if (response.status == 200) {
            state.logged = false;
            state.Uid = "";
            state.teljesNev = "";
            state.jogosultsag = 0;
            alert(response.data);
            document.getElementById("loginButton").innerHTML = "Login";
          } else {
            alert(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    generateSalt(state, payload) {
      var text = "";
      for (var i = 0; i < payload.hossz; i++)
        text += state.possible.charAt(
          Math.floor(Math.random() * state.possible.length)
        );
      state.userSalt = text;
    },
  },
  actions: {},
  modules: {},
});
