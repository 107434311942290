import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/felhasznalok",
    name: "felhasznalok",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FelhasznalokView.vue"),
  },
  {
    path: "/intezmenyek",
    name: "intezmenyek",
    component: () => import("../views/IntezmenyekView.vue"),
  },
  {
    path: "/kepzesek",
    name: "kepzesek",
    component: () => import("../views/KepzesekView.vue"),
  },
  {
    path: "/taborok",
    name: "taborok",
    component: () => import("../views/TaborokView.vue"),
  },
  {
    path: "/elojelentkezesek",
    name: "elojelentkezesek",
    component: () => import("../views/ElojelentkezesekView.vue"),
  },
  {
    path: "/backupRestore",
    component: () => import("../views/BackupRestoreView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
