<template>
  <div class="hello">
    <img v-if="msg=='Intézmények adatai'" class="img-fluid" alt="Vue logo" src="../assets/intezmeny.jpg" />
    <img v-if="msg=='Képzések adatai'" class="img-fluid" alt="Vue logo" src="../assets/kepzesek.jpg" />
    <img v-if="msg=='Felhasználók adatai'" class="img-fluid" alt="Vue logo" src="../assets/users.png" />
    <img v-if="msg=='Táborok adatai'" class="img-fluid" alt="Vue logo" src="../assets/camp.png" />
    <img v-if="msg=='Előjelentkezők adatai'" class="img-fluid" alt="Vue logo" src="../assets/elojel.jpg" />
    <h1>{{ msg }}</h1>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
img {
  height: 250px;
}
</style>
