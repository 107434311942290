<template>
  <div>
    <div id="view" :class="[{ collapsed: collapsed }]">
      <router-view />
    </div>
      <sidebar-menu
        theme:white-theme
        :menu="this.$store.state.menu"
        class="sidebar"
        :collapsed="collapsed"
        Toggle
        @item-click="onItemClick"
        @collapse="onCollapse"
      />
      <!--div class="container-fluid row">
        <h1></h1>
        <div
          class="col-1"
          style="
            text-align: right;
            margin-right: 5px;
            margin-top: 7px;
            color: #0d6efd;
          "
        ></div>
        <div class="col-xl-1 col-lg-2 col-md-3 col-sm-3 col-4">
          <div class=""></div>
        </div>
        <div
          class="col-xl-10 col-lg-9 col-md-8 col-sm-8 col-7"
          style="margin-left: -5px"
        >
          <router-link
            id="loginButton"
            class="btn btn-light btn-outline-primary float-end"
            to="/Login"
          >
            Login</router-link
          >
        </div>
      </div-->
      <!--nav>
        <router-link to="/">Home </router-link>
        <router-link
          v-if="this.$store.state.jogosultsag == 9"
          to="/felhasznalok"
          >| Felhasználók
        </router-link>
        <router-link v-if="this.$store.state.jogosultsag > 7" to="/intezmenyek"
          >| Intézmények
        </router-link>
        <router-link v-if="this.$store.state.jogosultsag > 6" to="/kepzesek"
          >| Képzések
        </router-link>
        <router-link v-if="this.$store.state.jogosultsag > 7" to="/taborok"
          >| Táborok
        </router-link>
        <router-link
          v-if="this.$store.state.jogosultsag == 9"
          to="/backupRestore"
          >| Mentés/visszatöltés
        </router-link>
      </nav-->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  props: {
    msg: String,
  },
  components: {},
  computed: {},
  data() {
    return {
      collapsed: true,
    };
  },
  methods: {
    refreshData() {},
    closeClick() {
      this.$router.push("/");
    },
    onItemClick(e, i) {
      console.log("onItemClick");
    },
    onCollapse(c) {
      console.log("onCollapse");
      this.collapsed = c;
    },
  },
  mounted: function () {
    this.refreshData();
  },
};
</script>

<style>
#view {
  padding-left: 350px;
}
#view.collapsed {
  padding-left: 50px;
}

.sidebar.v-sidebar-menu .vsm-arrow:after {
  content: "\f105";
  font-family: "FontAwesome";
}
.sidebar.v-sidebar-menu .collapse-btn:after {
  content: "\f07e";
  font-family: "FontAwesome";
}
#loginButton {
  margin-right: 100px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 100px;
}

nav {
  margin-top: 30px;
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
